// message-handler.service.ts
import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  /**
   * Obtiene el argumento del mensaje.
   *
   * @param arg - Argumento del mensaje.
   *
   * @description
   * Si el argumento es un string, lo toma y lo pone como mensaje en un objeto de tipo Message.
   * Si el argumento es un objeto, lo retorna.
   */
  private getMessageArg(arg: Message | string): Message {
    if (typeof arg === 'string') {
      return { detail: arg };
    }
    if (typeof arg === 'object') {
      return arg;
    }
    return arg;
  }

  showSuccess(arg: Message | string) {
    this.messageService.add({
      severity: 'success',
      ...this.getMessageArg(arg),
    });
  }

  showInfo(arg: Message | string) {
    this.messageService.add({ severity: 'info', ...this.getMessageArg(arg) });
  }

  showWarn(arg: Message | string) {
    this.messageService.add({ severity: 'warn', ...this.getMessageArg(arg) });
  }

  showError(arg: Message | string) {
    this.messageService.add({ severity: 'error', ...this.getMessageArg(arg) });
  }
}
